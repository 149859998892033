@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
    font-family: SuisseIntl;
    src: local(SuisseIntl-Regular-WebM),
    url("../fonts/SuisseIntl/SuisseIntl-Regular-WebM.eot") format("eot"),
    url("../fonts/SuisseIntl/SuisseIntl-Regular-WebM.woff") format("woff"),
    url("../fonts/SuisseIntl/SuisseIntl-Regular-WebM.woff2") format("woff2"),
    url("../fonts/SuisseIntl/SuisseIntl-Regular-WebM.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: SuisseIntl;
    src: local(SuisseIntl-SemiBold-WebM),
    url("../fonts/SuisseIntl/SuisseIntl-SemiBold-WebM.eot") format("eot"),
    url("../fonts/SuisseIntl/SuisseIntl-SemiBold-WebM.woff") format("woff"),
    url("../fonts/SuisseIntl/SuisseIntl-SemiBold-WebM.woff2") format("woff2"),
    url("../fonts/SuisseIntl/SuisseIntl-SemiBold-WebM.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: Tobias;
    src: local(Tobias-Regular),
    url("../fonts/Tobias/Tobias-Regular.otf") format("opentype"),
    url("../fonts/Tobias/Tobias-Regular.woff") format("woff"),
    url("../fonts/Tobias/Tobias-Regular.woff2") format("woff2"),
    url("../fonts/Tobias/Tobias-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
}